import { ButtonSecondaryWithIconRight } from '@components/shared/buttons/button-secondary-with-icon-right';
import Image from 'next/image';
import style from './index.module.scss'

interface IOurWorksItemProps {
    types: string[];
    title: string;
    subtitle: string;
    link: string;
    iconSrc: string;
    reversed: boolean;
}

export const OurWorksItem = ({title, subtitle, types, link, iconSrc, reversed }: IOurWorksItemProps) => {
    return(
        <div className={`${style.item} ${reversed ? style.item_reversed : null}`}>
            <div className={`${style.item__text__block}`}>
                <div className={`${style.item__title}`}>
                    {title}
                </div>
                <div className={`${style.item__types}`}>
                    {
                        types.map((item: string, index: number) => {
                            return (
                                <div className={`${style.item__type}`} key={index}>
                                    {item}
                                </div>
                            )
                        })
                    }
                </div>
                <div className={`${style.item__subtitle}`}>
                    {subtitle}
                </div>
                <div className={`${style.item__button}`}>
                    <ButtonSecondaryWithIconRight iconSrc={'/assets/icons/icn-arrow-right.svg'} text={'See full case study'} link={link}/>
                </div>
            </div>
            <div className={`${style.item__image__block}`}>
                <div className={`${style.item__image}`}>
                <Image loading='lazy' src={iconSrc} layout={'fill'} alt={title} sizes={'544px'}/>
                </div>
            </div>
        </div>
    )
}
