import style from './index.module.scss'
import Link from 'next/link'
import Image from 'next/image'
import { ReactSVG } from 'react-svg';

interface IButtonSecondaryWithIconRightProps {
    text: string;
    link?: string;
    iconSrc: string;
    showIntoView?: () => void;
}

export const ButtonSecondaryWithIconRight = ({ text, link, iconSrc, showIntoView }: IButtonSecondaryWithIconRightProps) => {
    if (link) {
        return (
            <Link href={link}>
                <a className={`${style.button}`}>
                    {text}
                    <ReactSVG alt={text} style={{maxWidth: '24px', maxHeight: '24px'}} src={iconSrc}/>
                </a>
            </Link>
        )
    } else {
        return (
            <button onClick={showIntoView} className={`${style.button}`}>
                {text}
                <ReactSVG alt={text} style={{maxWidth: '24px', maxHeight: '24px'}} src={iconSrc}/>
            </button>
        )
    }
}