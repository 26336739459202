import style from './index.module.scss'
import template from '@styles/reusable.styles.module.scss';
import { IOurWorksItems } from '@components/main-page/main-page.state';
import { OurWorksItem } from './our-works-item';

interface IOurWorksSectionProps {
    title: string;
    subtitle: string;
    items: IOurWorksItems[];
}


const OurWorksSection = ({ title, subtitle, items }: IOurWorksSectionProps) => {
    return (
        <section className={`${template.section}`}>
            <div className={`${template.container} ${template.container_small} ${style.container}`}>
                <h2 className={`${template.title} ${style.title}`}>
                    {title}
                </h2>
                <div className={`${template.subtitle} ${style.subtitle}`}>
                    {subtitle}
                </div>
            </div>
            <div className={`${template.container} ${template.container_large} ${style.container}`}>
                {
                    items.map((item: IOurWorksItems, index: number) => {
                        return (
                            <OurWorksItem reversed={index % 2 !== 0} link={item.link} iconSrc={item.iconSrc} title={item.title} subtitle={item.subtitle} types={item.types} key={index} />
                        )
                    })
                }
            </div>
        </section>
    )
}

export default OurWorksSection;
